(function($) {

    'use script';

    function siypsTheme() {
        var self = this;

        self.isShop = true;
        self.$quantityContainer = $('#nm-shop-events .nm-quantity-wrap')
        self.$body = $('body');
        self.$document = $(document);
        self.$widgetPanel = $('#nm-widget-panel');
        self.$widgetPanelOverlay = $('#nm-widget-panel-overlay');
        self.widgetPanelAnimSpeed = 250;
        self.$juliePopupButton = $('.single-product #nm-shop-sidebar-popup-button');
        self.$askJuliePopup = $('.single-product #nm-shop-sidebar-popup');
        self.$askJulieClose = $('.single-product .siyps-julie-header-close');
        self.$askJulieMessage = $('.single-product #message');
        self.$shopBrowseWrap = $('.nm-single-product');
        self.$askJulieCheckoutButton = $('#siyps-julie-add-to-cart-button');
        self.$askJulieCheckoutMessage = $('#siyps-julie-add-to-cart-button').prev();

        self.init();
    }

    siypsTheme.prototype = {

        footerSetUp: function() {
            $('#cmApp_signupEmail').attr('placeholder', 'Your email address');
        },

        atcEvents: function() {
            var self = this;

            // Bind: Single Event product "add to cart" buttons
            self.$body.on('click', '#nm-shop-events .single_add_to_cart_button', function(e) {
                var $thisButton = $(this);

                // Make sure the add-to-cart button isn't disabled
                if ( $thisButton.is('.disabled') ) { return; }

                // Only allow events product type
                if ($thisButton.hasClass('siyps-event-add-to-cart-button')) {
                    e.preventDefault();

                    self.$widgetPanelOverlay.addClass('nm-loader show');

                    // Set button disabled state
                    $thisButton.attr('disabled', 'disabled');

                    var $productForm = $thisButton.closest('form');

                    if (!$productForm.length) {
                        return;
                    }

                    var	data = {
                        product_id: $productForm.find("[name*='add-to-cart']").val(),
                        product_variation_data: $productForm.serialize()
                    };

                    // Trigger "adding_to_cart" event
                    self.$body.trigger('adding_to_cart', [$thisButton, data]);

                    var redirectToCheckout = false;

                    // Submit product form via Ajax
                    self.atcAjaxSubmitForm($thisButton, data, redirectToCheckout);
                }
            });
        },

        atcAskJulie: function() {
            var self = this;

            // Bind: Single Event product "add to cart" buttons
            self.$body.on('click', '#siyps-julie-add-to-cart-button', function(e) {
                var $thisButton = $(this);

                // Make sure the add-to-cart button isn't disabled
                if ( $thisButton.is('.disabled') ) { return; }

                e.preventDefault();

                self.$widgetPanelOverlay.addClass('nm-loader show');

                // Set button disabled state
                $thisButton.attr('disabled', 'disabled');

                var $productForm = $thisButton.closest('form');
                var message = $productForm.find("[name*='message']").val();

                if (!$productForm.length) {
                    return;
                }

                if (!message) {
                    return;
                }

                var data = {
                    product_id: $productForm.find("[name*='add-to-cart']").val(),
                    message: message,
                    product_variation_data: $productForm.serialize(),
                };

                // Trigger "adding_to_cart" event
                self.$body.trigger('adding_to_cart', [$thisButton, data]);

                // Submit product form via Ajax
                self.atcAjaxSubmitForm($thisButton, data, true);
            });
        },

        /**
         *	Submit product form via Ajax
         */
        atcAjaxSubmitForm: function($thisButton, data, goToCheckout) {
            var self = this;
            goToCheckout = typeof goToCheckout !== undefined ? goToCheckout : false;

            if (!data.product_id) {
                return;
            }

            var atcUrl = wc_add_to_cart_params.wc_ajax_url.toString().replace( 'wc-ajax=%%endpoint%%', 'add-to-cart=' + data.product_id + '&nm-ajax-add-to-cart=1' );

            // Submit product form via Ajax
            $.ajax({
                type: 'POST',
                url: atcUrl,
                data: data.product_variation_data,
                dataType: 'html',
                cache: false,
                headers: {'cache-control': 'no-cache'},
                error: function(XMLHttpRequest, textStatus, errorThrown) {
                    console.log('NM: AJAX error - atcAjaxSubmitForm() - ' + errorThrown);
                },
                success: function(response) {
                    var $response = $('<div>' + response + '</div>'), // Wrap the returned HTML string so we can get the replacement elements
                        $shopNotices = $response.find('#nm-shop-notices-wrap'), // Shop notices
                        hasError = ($shopNotices.find('.woocommerce-error').length) ? true : false, // Is there an error notice?
                        cartHash = ''; // Note: Add the cart-hash to an element (data attribute) in the redirect template?

                    // Get replacement elements/values
                    // Note: Using ".prop('outerHTML')" to convert the jQuery objects/elements into strings (since they are passed to the "added_to_cart" event below)
                    var fragments = {
                        '.nm-menu-cart-count': $response.find('.nm-menu-cart-count').prop('outerHTML'), // Header menu cart count
                        '#nm-shop-notices-wrap': $shopNotices.prop('outerHTML'),
                        '#nm-cart-panel': $response.find('#nm-cart-panel').prop('outerHTML') // Cart panel
                    };

                    // Replace cart/shop fragments
                    self.shopReplaceFragments(fragments);

                    // Trigger "added_to_cart" event
                    // Note: The "fragments" object is passed to make sure the various shop/cart fragments are updated
                    self.$body.trigger('added_to_cart', [fragments, cartHash]);

                    // Remove button disabled state
                    $thisButton.removeAttr('disabled');

                    if (hasError) {
                        setTimeout(function() {
                            // Hide cart panel and overlay
                            $('#nm-widget-panel-overlay').trigger('click');
                        }, 500);
                    }

                    $response.empty();

                    if (goToCheckout) {
                        window.location.href = '/checkout/';
                    }

                    self.$widgetPanelOverlay.trigger('click').removeClass('show nm-loader');
                }
            });
        },

        /**
         *	Shop: Replace fragments
         */
        shopReplaceFragments: function(fragments) {
            var $fragment;
            $.each(fragments, function(selector, fragment) {
                $fragment = $(fragment);
                if ($fragment.length) {
                    $(selector).replaceWith($fragment);
                }
            });
        },


        /**
         *	Quantity inputs: Bind buttons
         */
        quantityInputsBindButtons: function($container) {
            var self = this;

            // Add buttons
            // Note: Added these to the "../global/quantity-input.php" template instead (required for the Ajax Cart)
            //$container.find('.quantity').append('<div class="nm-qty-plus nm-font nm-font-media-play rotate-270"></div>').prepend('<div class="nm-qty-minus nm-font nm-font-media-play rotate-90"></div>');

            /*
             *	Bind buttons click event
             *	Note: Modified code from WooCommerce core (v2.2.6)
             */
            $container.off('click.nmQty').on('click.nmQty', '.nm-qty-plus, .nm-qty-minus', function() {
                // Get elements and values
                var $this = $(this),
                    $qty = $this.closest('.quantity').find('.qty'),
                    currentVal = parseFloat($qty.val()),
                    max = parseFloat($qty.attr('max')),
                    min = parseFloat($qty.attr('min')),
                    step = $qty.attr('step');

                // Format values
                if (!currentVal || currentVal === '' || currentVal === 'NaN') currentVal = 0;
                if (max === '' || max === 'NaN') max = '';
                if (min === '' || min === 'NaN') min = 0;
                if (step === 'any' || step === '' || step === undefined || parseFloat(step) === 'NaN') step = 1;

                // Change the value
                if ($this.hasClass('nm-qty-plus')) {
                    if (max && (max == currentVal || currentVal > max)) {
                        $qty.val(max);
                    } else {
                        $qty.val(currentVal + parseFloat(step));
                        self.quantityInputsTriggerEvents($qty);
                    }
                } else {
                    if (min && (min == currentVal || currentVal < min)) {
                        $qty.val(min);
                    } else if (currentVal > 0) {
                        $qty.val(currentVal - parseFloat(step));
                        self.quantityInputsTriggerEvents($qty);
                    }
                }
            });
        },


        /**
         *    Quantity inputs: Trigger events
         */
        quantityInputsTriggerEvents: function($qty) {
            var self = this;

            // Trigger quantity input "change" event
            $qty.trigger('change');

            // Trigger custom event
            self.$document.trigger('nm_qty_change', $qty);
        },

        /**
         *  Ask Julie: Toggle button
         */
        askJuliePopupToggle: function() {
            var self = this;
            if (self.$juliePopupButton.length) {
                var popupBtnOffset = (self.$juliePopupButton.hasClass('visible')) // Offset doesn't include button height
                        ? self.$juliePopupButton.offset().top + self.$juliePopupButton.outerHeight(true)
                        : self.$juliePopupButton.offset().top,
                    shopOffset = self.$shopBrowseWrap.offset().top,
                    tolerance = 190;

                // Is popup-button below start of the Shop (plus tolerance)?
                if (popupBtnOffset > (shopOffset + tolerance)) {
                    self.juliePopupButtonShow();
                } else {
                    self.juliePopupButtonHide();
                }
            }
        },

        /**
         * Bind ask Julie actions
         */
        askJuliePopupBind: function() {
            var self = this;
            if (self.$juliePopupButton.length) {
                self.$juliePopupButton.bind('click', function() {
                    self.juliePopupButtonHide();
                    self.askJuliePopupShow();
                });
                self.$askJulieClose.bind('click', function() {
                    self.askJuliePopupHide();
                    self.juliePopupButtonShow();
                });
            }
            if (self.$askJulieMessage.length) {
                self.$askJulieMessage.bind('keyup', function() {
                    var text = $(this).val();
                    if (text && text.trim()) {
                        $('#siyps-julie-add-to-cart-button').prop('disabled', false);
                    } else {
                        $('#siyps-julie-add-to-cart-button').prop('disabled', true);
                    }
                });
                self.$body.on('added_to_cart', function(){
                    self.$askJulieMessage.val('');
                });
            }
            if (self.$askJulieCheckoutMessage.length) {
                self.$askJulieCheckoutMessage.bind('keyup', function() {
                    var text = $(this).val();
                    if (text && text.trim()) {
                        self.$askJulieCheckoutButton.prop('disabled', false);
                    } else {
                        self.$askJulieCheckoutButton.prop('disabled', true);
                    }
                })
            }
            if (self.$askJulieCheckoutButton.length) {
                self.$body.on('added_to_cart', function(){
                    $(this).trigger('update_checkout');
                    self.$askJulieCheckoutMessage.val('');
                });
            }
        },

        /**
         *  Ask Julie popup: Show button
         */
        juliePopupButtonShow: function() {
            var self = this;
            self.$juliePopupButton.addClass('visible');
        },


        /**
         *  Ask Julie popup: Hide button
         */
        juliePopupButtonHide: function() {
            var self = this;
            self.$juliePopupButton.removeClass('visible');
        },

        /**
         *  Shop filters poup: Show
         */
        askJuliePopupShow: function() {
            var self = this;

            self.juliePopupButtonHide();

            self.$askJuliePopup.addClass('visible');

            // Bind: Filters popup outside click
            self.$document.bind('mouseup.filtersPopup', function(e) {
                if (! self.$askJuliePopup.is(e.target) // If the target of the click isn't the container ...
                    && self.$askJuliePopup.has(e.target).length === 0) // Nor a descendant of the container
                {
                    self.askJuliePopupHide();
                    self.juliePopupButtonShow();
                }
            });
        },


        /**
         *  Shop filters poup: Hide
         */
        askJuliePopupHide: function() {
            var self = this;

            self.$askJuliePopup.removeClass('visible');

            self.$document.unbind('mouseup.filtersPopup');
        },

        rareWinesHelper: function () {
            if (siyps_wp_vars && siyps_wp_vars.searchUrl) {
                if (nm_wp_vars) {
                    nm_wp_vars.searchUrl = siyps_wp_vars.searchUrl;
                }
            }
        },

        couponPopupHelper: function () {
            $('.showcoupon').bind('click.nmShowForm', function (e) {
                $('#nm-coupon-login-form > ul').remove();
                $('#nm-coupon-login-form input[name="coupon_code"]').val('');
            });
        },

        init: function() {
            var self = this;
            self.footerSetUp();
            self.atcEvents();
            self.atcAskJulie();
            self.askJuliePopupToggle();
            self.askJuliePopupBind();
            self.quantityInputsBindButtons(self.$quantityContainer);
            self.rareWinesHelper();
            self.couponPopupHelper();
        }
    };

    $(document).on('ready', function() {
        // Initialize script
        new siypsTheme();
    });

})(jQuery);
